.container {
    background: #FBFCFD;
    padding: 10px;
    font-family: "Lexend-Regular", sans-serif;
    padding-left: 20px;
    display: flex;
}

.container>.left-column,
.right-column {
    background: #FFFFFF;
}

.left-column {
    padding-left: 16px;
    padding-top: 21px;
    min-width: 350px;
}

.right-column {
    margin-left: 30px;
    width: 100%;
    padding: 30px;
    border: 1px solid #F2F2F2;
    border-radius: 10px;
    margin-top: 25px;
}

.back-to-button {
    text-decoration: none;
    margin-left: 16px;
}

.form-title {
    font-size: 19px;
    font-weight: 700;
    color: #00062B;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 35px;
}

.companies-field-container,
.guidelines-field-container {
    margin-top: 25px;
}

.field-label {
    text-transform: uppercase;
    font-weight: 500;
    color: #9FA2B4;
    font-size: 12px;
}

.companies-field,
.guidelines-field {
    background: #F2F2F2;
    border-radius: 10px;
    padding: 20px 30px;
}

.add-button {
    display: flex;
    margin-top: 28px !important;
}

.add-button-text {
    margin-top: 2px;
    margin-left: 7px;
}

.field-group {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-top: 28px;
}

.field {
    flex: 1;
}

.field-container {
    display: flex;
    align-items: center;
    gap: 40px;
}

.data-table {
    height: 371px;
    background: #fff;
    margin: 0 20px !important;
}

.guidelines-list-container {
    padding-bottom: 35px;
}

.guidelines-table-header {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
}

.guidelines-table-title {
    font-size: 19px;
    font-weight: 700;
    color: #00062B;
}

.button-link {
    text-decoration: none;
}

.save-button {
    margin-left: 50% !important;
}