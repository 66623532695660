.expandable-section {
    box-shadow: 1px 2px #F2F2F2 !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    margin-top: 0 !important;
    background: #F2F2F2;
}

.expandable-summary {
    background: #F2F2F2 !important;
}

.summary-content-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}