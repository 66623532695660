.login-container {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-container-form {
    padding: 20px;
}

.login-label {
    font-family: Novera;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
}

.login-btn {
    width: 100%;
}

.login-control,
.login-error-container,
.spinner-container {
    width: 300px;
}

.spinner-container {
    height: 245px;
}