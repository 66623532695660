.menu-item:hover {
   cursor: pointer;
}

.menu-item-container {
    margin: 20px 0;
    display: flex;
}

.title {
    margin: 0 10px 10px 0;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.87);
}