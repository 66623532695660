.monitoring-container {
    background: #FBFCFD;
    padding: 10px;
    font-family: "Lexend-Regular", sans-serif;
    padding-left: 20px;
    display: flex;
}

.monitoring-container>.left-column {
    background: #FFFFFF;
    height: 100%;
}

.left-column {
    padding-left: 0 !important;
    min-width: 350px;
}

.right-column {
    margin-left: 30px;
    width: 100%;
}

.button-link {
    text-decoration: none;
    margin-left: 16px;
}

.summary {
    margin-top: 20px !important;
    margin-left: 38px;
}

.summary-title {
    background: #F2F2F2;
    color: #00062B;
    padding: 16px;
    display: flex;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    align-items: center;
    justify-content: space-between;
}

.summary-title h3 {
    margin: 0;
}

.check-summary-with-table-content {
    background: #F2F2F2;
}

.check-summary-with-table-content-row {
    display: flex;
}