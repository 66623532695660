.filter-row {
    display: flex;
    gap: 40px;
    justify-content: flex-end;
}

.filter {
    display: flex;
    padding: 60px 24px 30px;
    flex-direction: column;
    gap: 30px;
}