.incident-status-label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: lightgray;
    margin: 10px;
}

.incident-status-label.escalation {
    color: blue;
}

.incident-status-label.active-breach {
    color: red;
}

.incident-status-label.resolved {
    color: green;
}