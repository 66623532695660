.sidebar {
    width: 100%;
}

.sidebar > .sidebar-menu-item {
    align-items: center;
}

.sidebar > .sidebar-menu-item {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    margin-left: 1rem;
}

.sidebar > .sidebar-menu-item > a {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #AEAEAE;
}

.sidebar > .sidebar-menu-item > a.selected {
    color: #00062B;
    cursor: default;
}

.sidebar > .sidebar-menu-item > a > span {
    margin-left: 1rem;
}


.logout-button {
    margin-left: 10px !important;
}

.settings-title {
    color: #9E9E9E;
    font-size: 14px !important;
}

.logo {
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: 0.5px;
}