.guideline-check-result-item {
    margin-top: 20px;
    padding-left: 16px;
    flex-basis: 250px;
}

.title {
    font-size: 12px;
    color: #AEAEAE;
    font-weight: 500;
}

.subtitle {
    font-size: 14px;
    color: #00062B;
}