.summary-content {
    padding: 20px 23px;
    background: #FFFFFF;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 1px 2px #F2F2F2;
}

.summary-content-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}