.funds-list-container {
    margin-top: 45px;
    margin-left: 35px;
    padding-bottom: 35px;
}

.funds-table-title {
    font-size: 19px;
    font-weight: 700;
    color: #00062B;
}

.funds-table-header {
    display: flex;
    padding: 32px;
    align-items: center;
    justify-content: space-between;
}

.data-table {
    height: 400px;
    background: #fff;
    margin: 0 35px;
}

.button-link {
    text-decoration: none;
}