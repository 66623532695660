.history-title {
    font-size: 12px;
    color: #AEAEAE;
    font-weight: 500;
    padding-left: 16px;
}

.history-container {
    margin-top: 58px;
}

.history-item {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start !important;
    margin-bottom: 12px !important;
    padding-top: 15px !important;
}

.history-item:hover {
    background: #D0D7FF !important;
}

.time {
    font-size: 12px;
    color: #8A9BFF;
    font-weight: 500;
}