.buttons-container {
    width: auto;
}

.company-tab-form {
    margin-top: 0 !important;
}

.company-tab-header {
    padding: 0 !important;
}