.form-control {
    width: 100%;
}

.requirements-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.restriction-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.delete-requirement-button-container {
    margin-top: 10px;
}

.restore-button {
    margin-right: 10px !important;
}