.group {
    box-sizing: border-box;
    padding: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.group-name {
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.group-actions {
    width: 40%;
}

.add-button-container {
    height: 28px;
    line-height: 28px;
}
