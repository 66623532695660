.cfi-block-item-title,
.cfi-block-item-subtitle {
    overflow: auto;
    margin-bottom: 5px;
}

.cfi-block-item-title {
    font-size: 16px;
    font-weight: 400;
    color: #00062B;
    height: 20px;
}

.cfi-block-item-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    height:40px;
    margin-bottom: 18px;
    line-height: 17px;
}