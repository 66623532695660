.cfi-modal-title,
.cfi-modal-subtitle {
    margin-bottom: 1rem;
}

.cfi-container,
.cfi-modal-title,
.cfi-modal-subtitle {
    font-family: 'Lexend-Regular', sans-serif;
}

.cfi-modal-title {
    font-size: 28px;
    font-weight: 400;
    line-height: 28px;
    color: #00062B;
}

.cfi-modal-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    color: #AEAEAE;
}
.cfi-container {
    height: 58vh;
    background: #F2F2F2;
    border-radius: 10px;
    padding: 16px;
    box-sizing: border-box;
    overflow: auto;
}