.tab-title {
    font-size: 19px;
    font-weight: 700;
    color: #00062B;
}

.tab-header {
    display: flex;
    padding: 0 0 32px 0;
    align-items: center;
    justify-content: space-between;
}

.field-group {
    display: flex;
    justify-content: space-between;
    gap: 80px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}