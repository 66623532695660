.dashboard-container {
    padding: 5px;
}

.circular-progress-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.status-link {
    text-decoration: none;
    cursor: pointer;
}