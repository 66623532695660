.layout-container {
    background: #FBFCFD;
    height: 100vh;
    overflow: auto;
    box-sizing: border-box;
    padding: 10px;
}
  
.layout-container > .left-column {
    background: #FFFFFF; 
    height: 100%;
}