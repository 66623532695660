.users-tab-content {
    padding-bottom: 20px;
}

.search-bar-container {
    display: flex;
}

.new-user-button {
    margin-left: 24px !important;
}

.field {
    flex: 1;
}

.buttons-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 20px;
}

.visibility-button {
    position: absolute !important;
    right: 7px;
    top: 8px;
}

.field-password-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

